import * as dayjs from 'dayjs';
import { reduce } from 'lodash';
import { mobileStrategy } from './isMobileStrategy';

export const dataFormatter = (avgResults) => {
  let toReturn = {
    mobile: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
      },
      GridItems: [],
      avg: 0,
    },
    desktop: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
      },
      GridItems: [],
      avg: 0,
    },
    url: '',
  };
  toReturn.url = avgResults[0].url;

  avgResults.forEach((item) => {
    if (mobileStrategy(item.strategy)) {
      toReturn.mobile.ChartMeta.weekLabel.push(item.createdDate);
      toReturn.mobile.ChartMeta.score.push(
        (item.lighthouseScore * 100).toFixed(0)
      );
    } else {
      toReturn.desktop.ChartMeta.weekLabel.push(item.createdDate);
      toReturn.desktop.ChartMeta.score.push(
        (item.lighthouseScore * 100).toFixed(0)
      );
    }
  });

  toReturn.mobile.avg =
    reduce(toReturn.mobile.ChartMeta.score, (sum, n) => {
      return parseInt(sum) + parseInt(n);
    }) / toReturn.mobile.ChartMeta.score.length;

  toReturn.desktop.avg =
    reduce(toReturn.desktop.ChartMeta.score, (sum, n) => {
      return parseInt(sum) + parseInt(n);
    }) / toReturn.desktop.ChartMeta.score.length;

  toReturn.desktop.ChartData = [
    {
      name: 'Average Lighthouse Score',
      type: 'line',
      data: toReturn.desktop.ChartMeta.score,
    },
  ];

  toReturn.mobile.ChartData = [
    {
      name: 'Average Lighthouse Score',
      type: 'line',
      data: toReturn.mobile.ChartMeta.score,
    },
  ];

  avgResults.forEach((item) => {
    const itemToPush = {
      id: `${item.PK}#${item.SK}`,
      dateAdded: parseInt(item.createdDate),
      url: item.URL,
      score: (item.lighthouseScore * 100).toFixed(0),
      lighthouse: item.lighthouse,
      crux: item.crux,
      cruxOverall: item.cruxOverall,
      indexId: `${item.GSI1PK}_${item.GSI1SK}`,
      pk: item.PK,
      sk: item.SK,
      gsi1pk: item.GSI1PK,
      gsi1sk: item.GSI1SK,
    };
    if (mobileStrategy(item.strategy)) {
      toReturn.mobile.GridItems.push(itemToPush);
    } else {
      toReturn.desktop.GridItems.push(itemToPush);
    }
  });

  return toReturn;
};
