import axios from 'axios';

import { GET_AVG_RESULT, GET_TEST_RESULT, GET_URL_RESULT } from './types';
import { setAlert } from './alert';

export const getAvgResult = (parameters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/result/${parameters.year}/${parameters.month}`
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_AVG_RESULT,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};

export const getTestResult = (parameters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/test-result/${parameters.id}/${parameters.subid}`
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_TEST_RESULT,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};

export const getUrlResult = (parameters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/url-result/${parameters.id}`
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URL_RESULT,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
