export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const GET_AVG_RESULT = 'GET_AVG_RESULT';
export const GET_TEST_RESULT = 'GET_TEST_RESULT';
export const GET_URL_RESULT = 'GET_URL_RESULT';

export const GET_URLS = 'GET_URLS';
export const GET_URL = 'GET_URL';
export const ADD_URL = 'ADD_URL';
export const UPDATE_URL = 'UPDATE_URL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
