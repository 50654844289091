import { filter } from 'lodash';
import * as dayjs from 'dayjs';
import { mobileStrategy } from './isMobileStrategy';
export const dataFormatter = (testResults) => {
  let result = {
    mobile: {
      avg: {},
      data: [],
    },
    desktop: {
      avg: {},
      data: [],
    },
  };

  const resultItems = filter(testResults, function (item) {
    return !item.PK.includes('AVG-RESULT-');
  });

  filter(testResults, function (item) {
    return item.PK.includes('AVG-RESULT-');
  }).forEach((item) => {
    if (mobileStrategy(item.strategy)) {
      result.mobile.avg = item;
    } else {
      result.desktop.avg = item;
    }
  });

  resultItems.forEach((item) => {
    const toReturn = {
      id: `${item.PK}#${item.SK}`,
      dateAdded: dayjs(parseInt(item.createdDate)).format(
        'DD-MM-YYYY HH:mm:ss'
      ),
      url: item.URL,
      score: (item.lighthouseScore * 100).toFixed(0),
      lighthouse: item.lighthouse,
      crux: item.crux,
      cruxOverall: item.cruxOverall,
      indexId: `${item.GSI1PK}_${item.GSI1SK}`,
      pk: item.PK,
      sk: item.SK,
      gsi1pk: item.GSI1PK,
      gsi1sk: item.GSI1SK,
    };
    if (mobileStrategy(item.strategy)) {
      result.mobile.data.push(toReturn);
    } else {
      result.desktop.data.push(toReturn);
    }
  });
  return result;
};
