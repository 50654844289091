import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    chart: [
      { main: ['#556cd6'] },
      { main: ['#19857b'] },
      { main: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'] },
      { main: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'] },
      { main: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'] },
      { main: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'] },
      { main: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#aa2e25', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#6d1b7b', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#482880', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#1769aa', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#008394', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
      { main: ['#357a38', '#FF8F6D', '#FFBD98', '#FFF2D4'] },
    ],
  },
});

export default theme;
