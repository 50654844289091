import { GET_URL_RESULT } from '../actions/types';

const initialState = {
  urlResult: {},
  urlResults: [],
  loadingUrlResult: true,
  errorUrlResult: {},
};

export default function urlResult(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_URL_RESULT:
      return {
        ...state,
        urlResult: {},
        urlResults: payload,
        loadingUrlResult: false,
      };

    default:
      return state;
  }
}
