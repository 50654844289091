import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/elements/Alert';
import Dashboard from './components/pages/Dashboard';
import AvgResult from './components/pages/Result/AvgResult';
import TestResult from './components/pages/Result/TestResult';
import UrlResult from './components/pages/Result/UrlResult';

import Urls from './components/pages/Urls/Urls';
import AddUrl from './components/pages/Urls/AddUrl';
import EditUrl from './components/pages/Urls/EditUrl';

import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import store from './store';

const PrivateRoute = ({ children }) => {
  const auth = localStorage.token;
  return auth
    ? children
    : (window.location.href =
        process.env.REACT_APP_URL_AUTH + '?src=' + window.location.href);
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const MainCustom = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Root>
          <Header />
          <VerticalNav />
          <MainCustom>
            <Toolbar />
            <Alert />
            <Routes>
              <Route path='/' element={<Dashboard />}></Route>
              <Route path='/dashboard' element={<Dashboard />}></Route>
              <Route path='/dashboard/:id' element={<Dashboard />}></Route>
              <Route path='/result/:id' element={<AvgResult />}></Route>
              <Route path='/test-result/:id' element={<TestResult />}></Route>
              <Route path='/url-result/:id' element={<UrlResult />}></Route>
              <Route
                path='/url'
                element={
                  <PrivateRoute>
                    <Urls />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path='/url/add'
                element={
                  <PrivateRoute>
                    <AddUrl />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path='/url/:id'
                element={
                  <PrivateRoute>
                    <EditUrl />
                  </PrivateRoute>
                }
              ></Route>
            </Routes>
          </MainCustom>
        </Root>
      </Router>
    </Provider>
  );
};

export default App;
