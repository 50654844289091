import * as dayjs from 'dayjs';
import { mobileStrategy } from './isMobileStrategy';

export const dataFormatter = (avgResults) => {
  let ChartDataDesktop = [];
  let ChartDataMobile = [];
  let ChartMetaDesktop = {
    weekLabel: [],
    score: [],
    url: [],
  };
  let ChartMetaMobile = {
    weekLabel: [],
    score: [],
    url: [],
  };

  const GridItemsDesktop = [];
  const GridItemsMobile = [];
  const metaScoreDesktop = [];
  const metaScoreMobile = [];

  avgResults.forEach((item) => {
    if (mobileStrategy(item.strategy)) {
      ChartMetaMobile.weekLabel.push(item.createdDate);
      ChartMetaMobile.score.push((item.score * 100).toFixed(0));
      item.meta.forEach((metaItem) => {
        metaScoreMobile.push({
          url: metaItem.url,
          score: (metaItem.score * 100).toFixed(0),
          createdDate: item.createdDate,
        });
      });
    } else {
      ChartMetaDesktop.weekLabel.push(item.createdDate);
      ChartMetaDesktop.score.push((item.score * 100).toFixed(0));
      item.meta.forEach((metaItem) => {
        metaScoreDesktop.push({
          url: metaItem.url,
          score: (metaItem.score * 100).toFixed(0),
          createdDate: item.createdDate,
        });
      });
    }
  });

  const urls = {
    mobile: [
      ...new Set(
        metaScoreMobile.map((item) => {
          return item.url;
        })
      ),
    ],
    desktop: [
      ...new Set(
        metaScoreDesktop.map((item) => {
          return item.url;
        })
      ),
    ],
  };

  ChartMetaDesktop.url = urls.desktop.map((url) => {
    const score = [];
    metaScoreDesktop.forEach((item) => {
      if (item.url == url) {
        score.push(item.score);
      }
    });

    return {
      name: url,
      type: 'line',
      data: score,
    };
  });

  let longestArray = 0;
  ChartMetaDesktop.url.forEach((item) => {
    if (item.data.length > longestArray) {
      longestArray = item.data.length;
    }
  });

  ChartMetaDesktop.url.forEach((item) => {
    if (item.data.length <= longestArray) {
      for (let index = item.data.length; index < longestArray; index++) {
        item.data.unshift(0);
      }
    }
  });

  ChartMetaMobile.url = urls.mobile.map((url) => {
    const score = [];
    metaScoreMobile.forEach((item) => {
      if (item.url == url) {
        score.push(item.score);
      }
    });

    return {
      name: url,
      type: 'line',
      data: score,
    };
  });

  longestArray = 0;
  ChartMetaMobile.url.forEach((item) => {
    if (item.data.length > longestArray) {
      longestArray = item.data.length;
    }
  });

  ChartMetaMobile.url.forEach((item) => {
    if (item.data.length <= longestArray) {
      for (let index = item.data.length; index < longestArray; index++) {
        item.data.unshift(0);
      }
    }
  });

  ChartDataDesktop = [
    {
      name: 'Average Lighthouse Score',
      type: 'line',
      data: ChartMetaDesktop.score,
    },
  ];
  ChartDataDesktop = ChartDataDesktop.concat(ChartMetaDesktop.url);

  ChartDataMobile = [
    {
      name: 'Average Lighthouse Score',
      type: 'line',
      data: ChartMetaMobile.score,
    },
  ];
  ChartDataMobile = ChartDataMobile.concat(ChartMetaMobile.url);

  avgResults.forEach((item) => {
    const itemToPush = {
      id: `${item.PK}#${item.SK}`,
      year: dayjs(parseInt(item.createdDate)).format('YYYY'),
      createdDate: parseInt(item.createdDate),
      dateAdded: new Date(item.createdDate),
      score: (item.score * 100).toFixed(0),
      indexId: `${item.GSI1PK}_${item.GSI1SK}`,
    };
    if (mobileStrategy(item.strategy)) {
      GridItemsMobile.push(itemToPush);
    } else {
      GridItemsDesktop.push(itemToPush);
    }
  });

  const result = {
    mobile: {
      ChartMeta: ChartMetaMobile,
      ChartData: ChartDataMobile,
      GridItems: GridItemsMobile,
    },
    desktop: {
      ChartMeta: ChartMetaDesktop,
      ChartData: ChartDataDesktop,
      GridItems: GridItemsDesktop,
    },
  };

  return result;
};
