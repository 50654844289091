import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import avgResult from './avgResult';
import testResult from './testResult';
import urlResult from './urlResult';
import url from './url';

export default combineReducers({
  alert,
  auth,
  avgResult,
  testResult,
  urlResult,
  url,
});
