import React, { useState, Fragment, useEffect } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as dayjs from 'dayjs';

import { BaseOptionChart } from '../elements/charts';
import { getAvgResult } from '../../actions/result';
import { dataFormatter } from '../../utils/chartDataFormatter';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const Dashboard = ({
  getAvgResult,
  avgResult: { avgResults, loadingAvgResult },
}) => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (isUndefined(params.id)) {
      navigate(`/dashboard/${dayjs().year()}`);
    }
  });

  useEffect(() => {
    if (!isUndefined(params.id)) {
      getAvgResult({ year: params.id, month: 'all' });
    }
  }, [getAvgResult, params]);

  let [dataChart] = useState({
    mobile: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
        url: [],
      },
      GridItems: [],
    },
    desktop: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
        url: [],
      },
      GridItems: [],
    },
  });

  const [sortModel, setSortModel] = useState([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);

  if (!loadingAvgResult) {
    dataChart = dataFormatter(avgResults);
  }

  const columns = [
    {
      field: 'year',
      headerName: 'Year',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const year = dayjs(parseInt(params.row.createdDate)).format('YYYY');
        return <Link to={`/dashboard/${year}`}>{year}</Link>;
      },
    },
    {
      field: 'month',
      headerName: 'Month',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const year = dayjs(parseInt(params.row.createdDate)).format('YYYY');
        const month = dayjs(parseInt(params.row.createdDate));
        return (
          <Link to={`/result/${year}-${month.format('M')}`}>
            {month.format('MMMM')}
          </Link>
        );
      },
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'dateTime',
      renderCell: (params) => {
        const dateParsed = dayjs(parseInt(params.row.createdDate)).format(
          'DD-MM-YYYY HH:mm:ss'
        );
        return dateParsed;
      },
    },
    {
      field: 'score',
      headerName: 'Lighthouse Score',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params) => {
        return (
          <strong>
            <Button
              variant='contained'
              color='primary'
              component={Link}
              to={`/test-result/${params.row.indexId}`}
              size='small'
            >
              Open
            </Button>
          </strong>
        );
      },
    },
  ];

  const chartOptions = {
    mobile: merge(BaseOptionChart(), {
      chart: { id: 'apex-mobile-id' },
      labels: dataChart.mobile.ChartMeta.weekLabel,
      dataLabels: {
        enabled: true,
      },
      xaxis: { type: 'datetime' },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          },
        },
      },
    }),
    desktop: merge(BaseOptionChart(), {
      chart: { id: 'apex-desktop-id' },
      labels: dataChart.desktop.ChartMeta.weekLabel,
      dataLabels: {
        enabled: true,
      },
      xaxis: { type: 'datetime' },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          },
        },
      },
    }),
    desktopSeries: dataChart.desktop.ChartData.map((item) => {
      return item.name;
    }).filter(
      (item) =>
        item !== 'Average Lighthouse Score' && item !== 'https://paperlust.co/'
    ),
    mobileSeries: dataChart.mobile.ChartData.map((item) => {
      return item.name;
    }).filter(
      (item) =>
        item !== 'Average Lighthouse Score' && item !== 'https://paperlust.co/'
    ),
  };

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Fragment>{children}</Fragment>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  useEffect(() => {
    const toHide = value == 0 ? 'desktop' : 'mobile';
    console.log(`dashboardUrl: ${value}-${toHide}`);
    chartOptions[`${toHide}Series`].map((item) => {
      ApexCharts.exec(`apex-${toHide}-id`, 'toggleSeries', item);
    });
  });

  return !loadingAvgResult ? (
    <Fragment>
      <Card>
        <CardHeader
          title='Average Lighthouse Score'
          subheader={`Calculated based on finished test that runs at ${dayjs(
            avgResults[0].createdDate
          ).format('YYYY')}`}
        />
      </Card>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          aria-label='Pagespeed strategy tab'
        >
          <Tab label='Desktop' {...a11yProps(0)}></Tab>
          <Tab label='Mobile' {...a11yProps(1)}></Tab>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Card>
          <CardContent>
            <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
              <Chart
                type='line'
                series={dataChart.desktop.ChartData}
                options={chartOptions.desktop}
                height={364}
              />
            </Box>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={dataChart.desktop.GridItems}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <CardContent>
            <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
              <Chart
                type='line'
                series={dataChart.mobile.ChartData}
                options={chartOptions.mobile}
                height={364}
              />
            </Box>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={dataChart.mobile.GridItems}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
    </Fragment>
  ) : (
    <LinearProgress color='primary' />
  );
};

Dashboard.propTypes = {
  getAvgResult: PropTypes.func.isRequired,
  avgResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  avgResult: state.avgResult,
});

export default connect(mapStateToProps, { getAvgResult })(Dashboard);
