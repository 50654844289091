import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';

import { updateNote } from '../../actions/note';
import { dataFormatter } from '../../utils/tableDataFormatter';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const NoteForm = ({
  updateNote,
  testResult: { testResults, loadingTestResult },
}) => {
  const { register, handleSubmit, setValue } = useForm();
  let [tableData] = useState({
    mobile: {
      avg: {},
      data: [],
    },
    desktop: {
      avg: {},
      data: [],
    },
  });
  if (!loadingTestResult) {
    tableData = dataFormatter(testResults);
  }
  const [desktopData, setDesktopData] = useState({});

  useEffect(() => {
    setDesktopData(tableData.desktop.avg);
  }, [testResults]);
  setValue('note', tableData.desktop.avg.note);

  const onSubmit = (data) => {
    updateNote({
      formData: data,
      mobile: tableData.mobile.avg,
      desktop: tableData.desktop.avg,
    });
  };

  return (
    <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={6}>
          <Typography gutterBottom>
            <strong>Note</strong>
          </Typography>
          <TextField
            id='outlined-multiline-static'
            multiline
            fullWidth
            rows={4}
            defaultValue=''
            {...register('note')}
          />{' '}
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' color='primary' type='submit'>
            Save
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

NoteForm.propTypes = {
  updateNote: PropTypes.func.isRequired,
  testResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
});

export default connect(mapStateToProps, { updateNote })(NoteForm);
