import axios from 'axios';
import { USER_LOADED, AUTH_ERROR } from './types';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(process.env.REACT_APP_URL_AUTH + 'auth/me');
    dispatch({ type: USER_LOADED, payload: res.data.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: AUTH_ERROR });
  }
};
