import { GET_AVG_RESULT } from '../actions/types';

const initialState = {
  avgResult: {},
  avgResults: [],
  loadingAvgResult: true,
  errorAvgResult: {},
};

export default function avgResult(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_AVG_RESULT:
      return {
        ...state,
        avgResult: {},
        avgResults: payload,
        loadingAvgResult: false,
      };

    default:
      return state;
  }
}
