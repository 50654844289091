import React, { useState, Fragment, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { reduce, filter, merge } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import * as dayjs from 'dayjs';

import { BaseOptionChart } from '../../elements/charts';
import { getUrlResult } from '../../../actions/result';
import { dataFormatter } from '../../../utils/urlDataFormatter';

import Crux from '../../elements/Crux';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const UrlResult = ({
  getUrlResult,
  urlResult: { urlResults, loadingUrlResult },
}) => {
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    getUrlResult({ id: id });
  }, [getUrlResult]);

  let [dataChart] = useState({
    mobile: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
        url: [],
      },
      GridItems: [],
      avg: {},
    },
    desktop: {
      ChartData: [],
      ChartMeta: {
        weekLabel: [],
        score: [],
        url: [],
      },
      GridItems: [],
      avg: {},
    },
    url: '',
  });

  const [sortModel, setSortModel] = useState([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);

  if (!loadingUrlResult) {
    const resultItems = filter(urlResults, function (item) {
      return !item.PK.includes('AVG-RESULT-');
    });

    dataChart = dataFormatter(resultItems);
  }
  const columns = [
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'dateTime',
      renderCell: (params) => {
        const row = params.row;
        const dateParsed = dayjs(parseInt(row.dateAdded)).format(
          'DD-MM-YYYY HH:mm:ss'
        );
        return (
          <RouterLink to={`/test-result/${row.gsi1pk}_${row.gsi1sk}`}>
            {dateParsed}
          </RouterLink>
        );
      },
    },
    {
      field: 'cruxOverall',
      headerName: 'Crux Overall',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cruxDetail',
      headerName: 'CRUX Detail',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Fragment>
            CLS: {row.crux.cls}
            <br />
            FCP: {row.crux.fcp}
            <br />
            FID: {row.crux.fid}
            <br />
            LCP: {row.crux.lcp}
          </Fragment>
        );
      },
    },
    {
      field: 'score',
      headerName: 'Lighthouse Score',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'scoreDetail',
      headerName: 'Lighthouse Score Detail',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Fragment>
            FCP: {row.lighthouse.fcp}
            <br />
            SI: {row.lighthouse.si}
            <br />
            LCP: {row.lighthouse.lcp}
            <br />
            TTI: {row.lighthouse.tti}
            <br />
            TBT: {row.lighthouse.tbt}
            <br />
            CLS: {row.lighthouse.cls}
          </Fragment>
        );
      },
    },
  ];

  const chartOptions = {
    mobile: merge(BaseOptionChart(), {
      chart: { id: 'apex-mobile-id' },
      labels: dataChart.mobile.ChartMeta.weekLabel,
      dataLabels: {
        enabled: true,
      },
      xaxis: { type: 'datetime' },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          },
        },
      },
    }),
    desktop: merge(BaseOptionChart(), {
      chart: { id: 'apex-desktop-id' },
      labels: dataChart.desktop.ChartMeta.weekLabel,
      dataLabels: {
        enabled: true,
      },
      xaxis: { type: 'datetime' },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          },
        },
      },
    }),
  };

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Fragment>{children}</Fragment>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  return !loadingUrlResult ? (
    <Fragment>
      <Card>
        <CardHeader
          title='Pagespeed & Lighthouse Score'
          subheader={`URL to test: ${dataChart.url}`}
        />
      </Card>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          aria-label='Pagespeed strategy tab'
        >
          <Tab label='Desktop' {...a11yProps(0)}></Tab>
          <Tab label='Mobile' {...a11yProps(1)}></Tab>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Card>
          <CardContent>
            Average Lighthouse Score:{' '}
            <strong>{dataChart.desktop.avg.toFixed(0)}</strong>
            <br />
            Run test on PageSpeed Insights:{' '}
            <Button
              href={`https://pagespeed.web.dev/report?url=${dataChart.url}&form_factor=dektop`}
              target='_blank'
              variant='contained'
              color='primary'
              size='small'
            >
              Run
            </Button>
          </CardContent>
          <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
            <Chart
              type='line'
              series={dataChart.desktop.ChartData}
              options={chartOptions.desktop}
              height={364}
            />
          </Box>
        </Card>
        <Card>
          <CardContent>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={dataChart.desktop.GridItems}
                    getRowHeight={({ id, densityFactor }) => {
                      return 150;
                    }}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <CardContent>
            Average Lighthouse Score:{' '}
            <strong>{dataChart.mobile.avg.toFixed(0)}</strong>
            <br />
            Run test on PageSpeed Insights:{' '}
            <Button
              href={`https://pagespeed.web.dev/report?url=${dataChart.url}&form_factor=mobile`}
              target='_blank'
              variant='contained'
              color='primary'
              size='small'
            >
              Run
            </Button>
          </CardContent>
          <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
            <Chart
              type='line'
              series={dataChart.mobile.ChartData}
              options={chartOptions.mobile}
              height={364}
            />
          </Box>
        </Card>
        <Card>
          <CardContent>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={dataChart.mobile.GridItems}
                    getRowHeight={({ id, densityFactor }) => {
                      return 150;
                    }}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
      <Card>
        <CardContent>
          <Crux />
        </CardContent>
      </Card>
    </Fragment>
  ) : (
    <LinearProgress color='primary' />
  );
};

UrlResult.propTypes = {
  getUrlResult: PropTypes.func.isRequired,
  urlResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  urlResult: state.urlResult,
});

export default connect(mapStateToProps, { getUrlResult })(UrlResult);
