import React, { useState, Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import * as dayjs from 'dayjs';

import { getTestResult } from '../../../actions/result';
import { dataFormatter } from '../../../utils/tableDataFormatter';
import Crux from '../../elements/Crux';
import NoteForm from '../../elements/NoteForm';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const TestResult = ({
  getTestResult,
  testResult: { testResults, loadingTestResult },
}) => {
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    const params = id.split('_');
    getTestResult({ id: params[0], subid: params[1] });
  }, [getTestResult]);

  let [tableData] = useState({
    mobile: {
      avg: {},
      data: [],
    },
    desktop: {
      avg: {},
      data: [],
    },
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);
  if (!loadingTestResult) {
    tableData = dataFormatter(testResults);
  }
  const columns = [
    {
      field: 'gsi1pk',
      headerName: 'Test Period',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const row = params.row;
        const week = row.gsi1pk.replace('TEST-', '');
        const sk = row.sk.replace('TEST-', '');
        return (
          <Link to={`/result/${week}`}>
            {dayjs(parseInt(sk)).format('YYYY MMM')}
          </Link>
        );
      },
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      type: 'dateTime',
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 250,
      headerAlign: 'center',
      renderCell: (params) => {
        const row = params.row;
        return <Link to={`/url-result/${row.pk}`}>{row.url}</Link>;
      },
    },
    {
      field: 'cruxOverall',
      headerName: 'Crux Overall',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'cruxDetail',
      headerName: 'CRUX Detail',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Fragment>
            CLS: {row.crux.cls}
            <br />
            FCP: {row.crux.fcp}
            <br />
            FID: {row.crux.fid}
            <br />
            LCP: {row.crux.lcp}
          </Fragment>
        );
      },
    },
    {
      field: 'score',
      headerName: 'Lighthouse Score',
      width: 175,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'scoreDetail',
      headerName: 'Lighthouse Score Detail',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Fragment>
            FCP: {row.lighthouse.fcp}
            <br />
            SI: {row.lighthouse.si}
            <br />
            LCP: {row.lighthouse.lcp}
            <br />
            TTI: {row.lighthouse.tti}
            <br />
            TBT: {row.lighthouse.tbt}
            <br />
            CLS: {row.lighthouse.cls}
          </Fragment>
        );
      },
    },
  ];

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Fragment>{children}</Fragment>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  return !loadingTestResult ? (
    <Fragment>
      <Card>
        <CardHeader
          title='Chrome User Experience Report & Lighthouse Score'
          subheader={`Tested at: ${dayjs(
            tableData.desktop.avg.createdDate
          ).format('DD-MM-YYYY HH:mm:ss')}`}
        />
      </Card>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='inherit'
          aria-label='Pagespeed strategy tab'
        >
          <Tab label='Desktop' {...a11yProps(0)}></Tab>
          <Tab label='Mobile' {...a11yProps(1)}></Tab>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant='body1'>
              Desktop Average Lighthouse Score:{' '}
              <strong>{(tableData.desktop.avg.score * 100).toFixed(0)}</strong>
            </Typography>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={tableData.desktop.data}
                    getRowHeight={({ id, densityFactor }) => {
                      return 150;
                    }}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant='body1'>
              Mobile Average Lighthouse Score:{' '}
              <strong>{(tableData.mobile.avg.score * 100).toFixed(0)}</strong>
            </Typography>
            <div style={{ height: 400, width: '100%' }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={tableData.mobile.data}
                    getRowHeight={({ id, densityFactor }) => {
                      return 150;
                    }}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </TabPanel>
      <Card>
        <CardContent>
          <NoteForm />
        </CardContent>
        <CardContent>
          <Crux />
        </CardContent>
      </Card>
    </Fragment>
  ) : (
    <LinearProgress color='primary' />
  );
};

TestResult.propTypes = {
  getTestResult: PropTypes.func.isRequired,
  testResult: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
});

export default connect(mapStateToProps, { getTestResult })(TestResult);
