import { GET_TEST_RESULT } from '../actions/types';

const initialState = {
  testResult: {},
  testResults: [],
  loadingTestResult: true,
  errorTestResult: {},
};

export default function testResult(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TEST_RESULT:
      return {
        ...state,
        testResult: {},
        testResults: payload,
        loadingTestResult: false,
      };

    default:
      return state;
  }
}
