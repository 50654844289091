import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { getUrl, editUrl } from '../../../actions/url';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const EditUrl = ({ getUrl, editUrl, url: { url, loadingUrl } }) => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    getUrl(params);
  }, [getUrl, params]);
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    editUrl({ formData: data, originalData: url[0], navigate: navigate });
  };

  return !loadingUrl && url.length > 0 ? (
    <Fragment>
      <Card>
        <CardHeader
          title='URLs'
          subheader={`We suggest you to not to change URL to test in order to maintain the data consistency. Use this only to fix typo, not replacing the value with other URL.`}
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={6}>
              <Typography gutterBottom>
                <strong>URL</strong>
              </Typography>
              <Input fullWidth {...register('URL')} defaultValue={url[0].URL} />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' type='submit'>
                Save
              </Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Fragment>
  ) : (
    <LinearProgress color='primary' />
  );
};

EditUrl.propTypes = {
  getUrl: PropTypes.func.isRequired,
  editUrl: PropTypes.func.isRequired,
  url: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  url: state.url,
});

export default connect(mapStateToProps, { getUrl, editUrl })(EditUrl);
