import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { addUrl } from '../../../actions/url';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

const AddUrl = ({ addUrl }) => {
  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = (data) => {
    addUrl({ formData: data, navigate: navigate });
  };

  return (
    <Fragment>
      <Card>
        <CardHeader title='URLs' subheader={`add URL here`} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={6}>
              <Typography gutterBottom>
                <strong>URL</strong>
              </Typography>
              <Input fullWidth {...register('url')} defaultValue={''} />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' type='submit'>
                Save
              </Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Fragment>
  );
};

AddUrl.propTypes = {
  addUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addUrl })(AddUrl);
