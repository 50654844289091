import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import * as dayjs from 'dayjs';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getUrls } from '../../../actions/url';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const Urls = ({ getUrls, url: { urls, loadingUrl } }) => {
  useEffect(() => {
    getUrls();
  }, [getUrls]);

  let [GridItems] = useState([]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'dateAdded',
      sort: 'desc',
    },
  ]);
  if (!loadingUrl && urls.length > 0) {
    GridItems = urls.map((item) => {
      return {
        id: `${item.PK}#${item.SK}`,
        dateAdded: dayjs(parseInt(item.createdDate)).format(
          'DD-MM-YYYY HH:mm:ss'
        ),
        url: item.URL,
        pk: item.PK,
        sk: item.SK,
      };
    });
  }

  const columns = [
    {
      field: 'url',
      headerName: 'URL',
      width: 500,
      headerAlign: 'center',
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 250,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction='row'>
            <Button
              variant='contained'
              color='secondary'
              size='small'
              component={RouterLink}
              to={`/url-result/${params.row.sk}`}
            >
              Open Result
            </Button>
            <Button
              variant='contained'
              color='primary'
              size='small'
              component={RouterLink}
              to={`/url/${params.row.sk}`}
            >
              Update
            </Button>
          </Stack>
        );
      },
    },
  ];

  return !loadingUrl && urls.length > 0 ? (
    <Fragment>
      <Card>
        <CardHeader
          title='URLs'
          subheader={`add and update URL to check here`}
        />
        <CardContent>
          <Stack spacing={2}>
            <Button
              size='small'
              variant='contained'
              component={RouterLink}
              to='/url/add'
              startIcon={<AddIcon />}
            >
              New Link
            </Button>
          </Stack>
          <div style={{ height: 400, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={GridItems}
                  columns={columns}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  ) : (
    <LinearProgress color='primary' />
  );
};

Urls.propTypes = {
  getUrls: PropTypes.func.isRequired,
  url: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  url: state.url,
});

export default connect(mapStateToProps, { getUrls })(Urls);
