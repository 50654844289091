import { GET_URLS, GET_URL, ADD_URL, UPDATE_URL } from '../actions/types';

const initialState = {
  url: {},
  urls: [],
  loadingUrl: true,
  errorUrl: {},
};

export default function url(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_URLS:
      return {
        ...state,
        url: {},
        urls: payload,
        loadingUrl: false,
      };
    case GET_URL:
      return {
        ...state,
        url: payload,
        urls: [],
        loadingUrl: false,
      };
    case ADD_URL:
      return {
        ...state,
        url: {},
        urls: payload,
        loadingUrl: false,
      };
    case UPDATE_URL:
      return {
        ...state,
        url: {},
        urls: payload,
        loadingUrl: false,
      };

    default:
      return state;
  }
}
