import axios from 'axios';

import { GET_URL_RESULT } from './types';
import { setAlert } from './alert';

export const updateNote = (parameter) => async (dispatch) => {
  try {
    const { formData, mobile, desktop } = parameter;
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}/url-note/${desktop.PK}/${desktop.SK}/${mobile.SK}`,
      formData
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URL_RESULT,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
