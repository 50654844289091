import { USER_LOADED, AUTH_ERROR } from '../actions/types';
import setAuthToken from '../utils/setAuthToken';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: false,
      };
    case AUTH_ERROR:
      localStorage.removeItem('token');
      setAuthToken();
      // window.location.href =
      //   process.env.REACT_APP_URL_AUTH + '?src=' + window.location.href;
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    default:
      return state;
  }
}
