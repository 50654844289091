import { v4 as uuidv4 } from 'uuid';

import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert =
  (msg, alertType, timeout = 10000, state = true) =>
  (dispatch) => {
    dispatch(removeAlert({ source: 'setAlert' }));
    const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: {
        msg,
        alertType,
        id,
        timeout,
        state,
      },
    });
  };

export const removeAlert =
  ({ source }) =>
  (dispatch) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT });
    }, process.env.REACT_APP_CUSTOM_TIMER);
  };
