import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const Crux = (props) => {
  return (
    <Fragment>
      <Stack spacing={2} direction='row'>
        <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
          <strong>
            <a
              href='https://web.dev/chrome-ux-report-pagespeed-insights/'
              target='_blank'
            >
              CrUX
            </a>
          </strong>{' '}
          is a collection of real user experiences from. The metrics that
          represent real user experiences are:
          <br />
          <strong>FID</strong>: <strong>First Input Delay</strong>, measures the
          interactivity of the page, from the user's first interaction to the
          time the page responds to it.
          <br />
          <strong>LCP</strong>: <strong>Largest Contentful Paint</strong>,
          measures the time until the page displays what is likely its main
          content, like a hero image or heading.
          <br />
          <strong>FCP</strong>: <strong>First Contentful Paint</strong>,
          measures the time until the page displays something in the foreground,
          like some text or a logo.
          <br />
          <strong>CLS</strong>: <strong>Cumulative Layout Shift</strong>,
          measures the degree of layout instability on the page, due to shifts
          like asynchronously loaded content being injected
          <br />
        </Box>
        <Box sx={{ p: 3, pb: 1 }} dir='ltr'>
          <strong>
            <a
              href='https://developers.google.com/web/tools/lighthouse'
              target='_blank'
            >
              Lighthouse
            </a>
          </strong>{' '}
          is an open-source, automated tool for improving the quality of web
          pages.
          <br />
          <strong>FCP</strong>:<strong>First Contentful Paint</strong>, measures
          how long it takes the browser to render the first piece of DOM content
          after a user navigates to your page.
          <br />
          <strong>SI</strong>:<strong>Speed Index</strong>, measures how quickly
          content is visually displayed during page load. <br />
          <strong>LCP</strong>:<strong>Largest Contentful Paint</strong>,
          reports the render time of the largest image or text block visible
          within the viewport, relative to when the page first started loading.{' '}
          <br />
          <strong>TTI</strong>:<strong>Time To Interactive</strong>, measures
          how long it takes a page to become fully interactive. <br />
          <strong>TBT</strong>:<strong>Total Blocking Time</strong>, measures
          the total amount of time that a page is blocked from responding to
          user input, such as mouse clicks, screen taps, or keyboard presses.{' '}
          <br />
          <strong>CLS</strong>:<strong>Cumulative Layout Shift</strong>, measure
          of the largest burst of layout shift scores for every unexpected
          layout shift that occurs during the entire lifespan of a page. <br />
        </Box>
      </Stack>
    </Fragment>
  );
};

Crux.propTypes = {};

export default Crux;
