import axios from 'axios';

import { GET_URLS, GET_URL } from './types';
import { setAlert } from './alert';

export const getUrls = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/url`);
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URLS,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const getUrl = (parameters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}/url/${parameters.id}`
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URL,
      payload: data.result,
    });
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const addUrl = (parameter) => async (dispatch) => {
  try {
    const { formData, navigate } = parameter;
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/url`,
      formData
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URLS,
      payload: data.result,
    });
    navigate(`/url`);
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const editUrl = (parameter) => async (dispatch) => {
  try {
    const { formData, originalData, navigate } = parameter;
    const res = await axios.put(
      `${process.env.REACT_APP_URL_BACKEND}/url/${originalData.SK}`,
      formData
    );
    const data = {
      result: res.data.Items,
    };
    dispatch({
      type: GET_URLS,
      payload: data.result,
    });
    navigate(`/url`);
  } catch (error) {
    dispatch(setAlert(`${error.message}`, 'error'));
    console.log(error);
  }
};
export const viewUrl = () => async (dispatch) => {};
